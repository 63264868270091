import * as React from "react"
import styled from "styled-components"
import { FC, useEffect } from "react"
import GLogo from "../../../gatsby/components/GLogo"
import useQueryString from "../../hooks/useQueryString"
import { submitFeedback } from "../../api/feedbackApi"

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`
const IframeCont = styled.div`
  width: 100%;
  max-width: 800px;
  height: 1000px;
  margin: auto;
`

const Iframe = styled.iframe`
  width: 100%;
  max-width: 800px;
`

const Logo = styled.div`
  margin: 50px;
  display: flex;
  justify-content: center;
`
const Text = styled.div`
  width: 100%;
  max-width: 600px;
  margin: auto;
  margin-bottom: 20px;
  padding: 0 20px;
`

interface Props {}

const FeedbackPage: FC<Props> = () => {
  // feedback: p, m, g

  const [feedback, setFeedback] = useQueryString("f", "")
  const [orderId, setOrderId] = useQueryString("o", "")
  const [userFirstName, setUserFirstName] = useQueryString("u", "")
  const [userEmail, setUserEmail] = useQueryString("e", "")

  useEffect(() => {
    if (orderId && feedback) {
      submit()
    }
  }, [orderId, feedback])

  const submit = async () => {
    try {
      await submitFeedback(orderId, feedback)
    } catch (e) {}
  }

  if (!feedback) {
    return <div></div>
  }

  return feedback !== "g" ? (
    <Container>
      <Logo>
        <GLogo />
      </Logo>
      <Text>
        Thank you for your response. Please leave any comments in the form below. All feedback is
        useful for us as we work towards becoming a better company.
      </Text>
      <IframeCont>
        <Iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSeLQnVRW5FlDk3BPZNT-cUh6JjxLo-t5FXYuGAOTwqr8atUqw/viewform?embedded=true"
          height="1000"
          frameBorder="0"
        >
          Loading…
        </Iframe>
      </IframeCont>
    </Container>
  ) : (
    <iframe
      src={`https://www.reviews.io/store/landing_new_review?store=social-supermarket&user=${userFirstName}&email=${userEmail}&type=company&order_id=${orderId}`}
      style={{ width: "100%", height: "100vh", margin: "auto" }}
      frameBorder="0"
    >
      Loading…
    </iframe>
  )
}

export default FeedbackPage
