import * as api from "../../api/general"
import { getConfig } from "../../config/index"

export const submitFeedback = async (orderId: string, happiness: string): Promise<any> => {
  const response = await api.post(
    `${getConfig().baseUrl}/wp-json/social-supermarket/v1/feedback/submit`,
    {
      orderId,
      happiness,
    }
  )

  if (response.code) {
    throw response.message
  }

  return response
}
